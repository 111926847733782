import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useContext, useEffect, useState } from "react";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import TextArea from "Components/FormControl/TextArea/TextArea";
// SCSS
import styles from "./cancelNote.module.scss";
import { useFormikContext } from "formik";
import { AppContext } from "Context/AppContext";
import { useDelivery } from "../hooks/useDelivery";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeliveryAndService = ({
    showModal,
    pageTitle,
    handleCancelClick,
    handleSuccess,
    serviceChargeData
}) => {
    const [serviceCharge, setServiceCharge] = useState(0)
    const {
        handleDeliveryChargeChange,
    } = useDelivery();

    useEffect(() => {
        setServiceCharge(serviceChargeData)
    }, [serviceChargeData])

    const submit = () => {
        handleDeliveryChargeChange(serviceCharge)
        handleSuccess(serviceCharge)
    };

    return (
        <React.Fragment>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                onClose={() => { handleCancelClick() }}
                classes={{
                    paper: styles.modalDialog,
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogContent className={`${styles.modalContent} px-0 py-0`}>
                    <div className={`text-center ${styles.modalBody}`}>
                        <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                            Update {pageTitle}
                        </h5>
                        <div className="row">
                            <div className={`col-md-4 col-xs-12 mt-5 ${styles.marginAuto}`}>
                                <TextField
                                    type="text"
                                    label={pageTitle}
                                    value={serviceCharge}
                                    onChange={(e) => {
                                        setServiceCharge(e.target.value)
                                    }}
                                />
                            </div>
                        </div>

                        <span
                            data-dismiss="modal"
                            onClick={() => { submit() }}
                            className="btn gray-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
                        >
                            Save
                        </span>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default DeliveryAndService;
