import { TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React from "react";
// SCSS
import styles from "./OrderFund.module.scss";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FundModal = (props) => {

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.onClose}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {props.pageTitle}
            </h5>
            <div className="row">
              <div className={`col-md-4 col-xs-12 mt-5 ${styles.marginAuto}`}>
                <TextField
                  type="text"
                  disabled={props.disebled === true ? props.disebled : false}
                  label={props.pageTitle}
                  value={props.value}
                  required={true}
                  onChange={(e) => {
                    if (/^[0-9]*(\.[0-9]*)?$/.test(e.target.value)) {
                        props.onChange(e.target.value)
                    }
                  }}
                />
              </div>
            </div>
            {
              !props.disebled && <div className="row">
              <div
                className={`col-md-8 col-xs-12 mt-2 mb-5 ${styles.marginAuto}`}
              >
                <p className={`${styles.infoText}`}>
                  Amount should be just number or decimal number.
                </p>
              </div>
            </div>
            }
            
            <span
              data-dismiss="modal"
              onClick={props.onSave}
              className="btn gray-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
            >
              Save
            </span>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default FundModal;
