import React from "react";
import Select from "Components/FormControl/Select/NewSelect";
import TotalSummary from "./TotalSummary";
import InternalNote from "./Internotes/index";
import { useFormikContext } from "formik";
import { useMemo } from "react";
import CardForm from "./CardForm/CardForm";
import CardList from "./CardList";
import { useOrderAttributeProvider } from "../context/order";
import EditOrderPayment from "./EditOrderPayment";
import PayabliPayment from "Components/PayabliPaymentForm/PayabaliCardForm";
import { PAYMENT_METHODS, } from "Helpers/Constants";
const PaymentAndTotal = (props) => {
  const formik = useFormikContext();
  const { orderDetail, module, setCurrentCard } = useOrderAttributeProvider();
  const payment_options = useMemo(() => {
    let payment_type = [
      {
        id: "credit_card",
        name: "Credit Card / Debit Card",
      },
    ];
    if (formik.values.customer_info.customer_id !== 0) {
      payment_type.push({
        id: "saved_cards",
        name: "Saved Cards",
      });
    }
    if (formik.values.customer_info.is_house_account && (!formik.values.is_subscribe_order || module != "new-order")) {
      payment_type.push({
        id: "house_account",
        name: "House Account",
      });
    }

    if(formik.values.delivery_information.delivery_type_id > 2 || module === "edit-order") {
      payment_type.push({
        id: "dove",
        name: "Dove",
      });
    }

    if (formik.values.order_type_identifier === 1) {
      payment_type.push({
        id: "replace_order",
        name: "Replace order",
      });
    }

    if (module === "edit-order") {
      payment_type.push({
        id: "card",
        name: "Credit Card / Debit Card",
      });
      payment_type.push({
        id: "paypal",
        name: "Paypal Account",
      });
      payment_type.push({
        id: "applepay",
        name: "Apple Pay Account",
      });
      payment_type.push({
        id: "googlepay",
        name: "Google Pay Account",
      });
    }
    if(module === "edit-order" && orderDetail && orderDetail.source === "walkin" ) {
      payment_type.push({
        id: "cash",
        name: "Cash",
      });
    }
    return payment_type;
  }, [
    formik.values.customer_info.customer_id,
    formik.values.order_type_identifier,
    formik.values.delivery_information.delivery_type_id,
  ]);
  return (
    <div className={"row mt-5"}>
      <div className="col-md-4">
        <h3 className="bold">Payment Types</h3>
        <Select
          disabled={
            formik.values.order_type_identifier === 1 ||
            props.module === "edit-order"
          }
          value={formik.values.payment_type}
          label="Payment Type"
          options={payment_options}
          onChange={(value) => {
            if (value.id !== "saved_cards") {
              setCurrentCard(null);
            }
            formik.setValues((v) => {
              let billing_info = { ...formik.values.billing_info };
              billing_info.customer_billing_first_name = "";
              billing_info.customer_billing_last_name = "";
              billing_info.customer_billing_address_2 = "";
              billing_info.customer_billing_address_1 = "";
              billing_info.customer_billing_city = "";
              billing_info.customer_billing_state = "";
              billing_info.customer_billing_country = "";
              billing_info.customer_billing_zipcode = "";
              return {
                ...v,
                payment_type: value.id,
                billing_info,
              };
            });
          }}
        />
        <div>
          {module === "edit-order" && <EditOrderPayment />}
          {/* //PAYABLI */}
          {module !== "edit-order" &&
            (formik.values.delivery_information?.zipcode?.length === 5 ||
            formik.values?.delivery_information?.delivery_type ===
              "Pickup/Will-call" ? (
              formik.values.billing_info.payment_method ===
              PAYMENT_METHODS.PAYABLI ? (
                <div
                  style={{ 
                    padding: 0,
                    border:'none',
                    boxShadow: "rgb(196 196 196 / 35%) 0px 0px 14px",
                    borderRadius: "20px",
                    paddingBottom: "0.1px" 
                  }}
                >
                  <PayabliPayment
                    customer_info={formik.values.customer_info}
                    className={
                      formik.values.payment_type === "credit_card"
                        ? ""
                        : "d-none"
                    }
                    entryPoint={formik.values.payabli_paypoint}
                  />
                </div>
              ) : (
                <CardForm
                  customer_info={formik.values.customer_info}
                  className={
                    formik.values.payment_type === "credit_card" ? "" : "d-none"
                  }
                />
              )
            ) : (
              <CardForm
                customer_info={formik.values.customer_info}
                className={
                  formik.values.payment_type === "credit_card" ? "" : "d-none"
                }
              />
            ))}
          {formik.values.payment_type === "saved_cards" &&
            (formik.values.delivery_information?.zipcode?.length === 5 || 
              formik.values.delivery_information.order_type === 1 ) && (
              <CardList />
            )}
        </div>
      </div>
      <div className="col-md-4">
        <h3 className="bold">Summary</h3>
        <div>
          <TotalSummary module={props.module}/>
        </div>
      </div>
      <div className="col-md-4">
        <h3 className="bold">Internal Notes</h3>
        <div>
          <InternalNote />
        </div>
      </div>
    </div>
  );
};

export default PaymentAndTotal;
