import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";

const Error = ({ open, handleClose }) => {
  const formik = useFormikContext();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Please resolve the below issues before processing order."}
      </DialogTitle>
      <DialogContent>
        {formik.errors.recipients && (
          <div>
            <h4>Recipient Errors</h4>
            {formik.errors.recipients.map((rec, i) => {
              return (
                <div key={i}>
                  {!!rec && <h6>Recipent {i + 1}</h6>}
                  <ul>
                    {!!rec &&
                      Object?.keys(rec).map((err) => (
                        <li key={err}>{rec[err]}</li>
                      ))}
                  </ul>
                </div>
              );
            })}
          </div>
        )}

        {formik.errors.delivery_information && (
          <div>
            <h4>Delivery Information Errors</h4>
            <ul>
              {Object.keys(formik.errors.delivery_information).map((e) => (
                <li key={e}>
                  {formik.errors.delivery_information[e]}
                  {e === "not_deliverable" && (
                    <span className="d-inline-block ml-2">
                      {formik.values?.delivery_information?.not_deliverable?.join(
                        ", "
                      )}{" "}
                      is not deliverable to zipcode{" "}
                      {formik.values.delivery_information.zipcode}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

        {formik.errors.customer_info && (
          <div>
            <h4>Customer Information Errors</h4>
            <ul>
              {Object.keys(formik.errors.customer_info).map((e) => (
                <li key={e}>{formik.errors.customer_info[e]}</li>
              ))}
            </ul>
          </div>
        )}

        {formik.errors.billing_info && (
          <div>
            <h4>Billing Information Errors</h4>
            <ul>
              {Object.keys(formik.errors.billing_info).map((e) => (
                <li key={e}>{formik.errors.billing_info[e]}</li>
              ))}
            </ul>
          </div>
        )}
        {formik?.errors?.payment_type && (
          <div>
            <h4>Please select an payment type</h4>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Error;
