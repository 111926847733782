import { TextField, Tooltip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React, { useEffect, useState } from "react";

const ProductPriceEdit = (props) => {
  const [price, setPrice] = useState(0)

  useEffect(() => {
    setPrice(props.editData?.data?.selectedVariant.price.toString())
  }, [])

  console.log("props.editData", props.editData)

  const editProductPrice = async () => {
    if (props.editData.title === 'Products') {
      await props.editProduct({ index: props.editData.index, price: price })
      props.onClose()
    } else {
      await props.editAddon({ index: props.editData.index, price: price })
      props.onClose()
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <form
        className="row"
        style={{ maxWidth: "600px", padding: "2rem", overflowX: "hidden" }}
      >
        <div className="col-12 my-3">
          <div
            style={{
              width: "120px",
              height: "120px",
              backgroundColor: "#ccc",
              display: "grid",
              placeItems: "center",
              position: "relative",
            }}
          >
            <img
              src={props?.editData?.data?.selectedVariant?.image}
              alt="Variation"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
        <TextField
          label="Title"
          className="col-md-6 py-2 px-1"
          id="title"
          title={props.editData?.data?.title}
          value={props.editData?.data?.title.length > 20 ? `${props.editData?.data?.title.slice(0, 20)}...` : props.editData?.data?.title}
          disabled={true}

        />
        <TextField
          label="Variation name"
          className="col-md-6 py-2 px-1"
          id="selectedVariant.title"
          disabled={true}
          value={props.editData?.data?.selectedVariant?.title}
          title={props.editData?.data?.selectedVariant?.title}
        />

        <TextField
          label="Price"
          type="text"
          className="col-md-6 py-2 px-1"
          id="selectedVariant.price"
          onChange={(e) => {
            if (/^[0-9]*(\.[0-9]*)?$/.test(e.target.value)) {
              let price = e.target.value;
              setPrice(price)
            }
          }}
          value={price}
        />
        <div className="col-12 text-center mt-3">
          <button className="btn pink-btn bold" onClick={() => { editProductPrice() }}>
            Update
          </button>
        </div>
      </form>
    </Dialog>
  );
};

export default ProductPriceEdit;
