import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ReactComponent as ReactLogo } from "Assets/images/add-recipient.svg";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect } from "react";
import { newRecipient, useOrderAttributeProvider } from "../context/order";
import Recipent from "./recipent";
import { ORDER_SOURCE, PAYMENT_METHODS } from "Helpers/Constants";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "3rem",
  },
  mapWrap: {
    border: "1px solid #ccc",
    padding: "8px",
    iframe: {
      display: "block",
    },
  },
});

const Recipents = () => {
  const classes = useStyles();
  const formik = useFormikContext();

  const { active, setActive, module } = useOrderAttributeProvider();
  const handleChange = (event, newValue) => {
    setActive(newValue);
  };
  const handleClose = useCallback(
    (event, index) => {
      
      // stop event from propagating to the target element's parent
      event.stopPropagation();
      let re = [...formik.values.recipients];
      
      if (re.length === 1) return;
      let deleted_recipient_ids = [...formik.values.deleted_recipient_ids];

      if (module === "edit-order") {
        if (re[index]?.sql_id) {
          deleted_recipient_ids.push(re[index].sql_id);
        }
      }
      re.splice(index, 1);
      console.log(re);
      setActive(0);
      formik.setValues((v) => {
        return {
          ...v,
          recipients: re,
          deleted_recipient_ids,
        };
      });

      // if (index === 0) {
      //   return;
      // } else {

      // }
    },
    [active, formik.values.recipients]
  );

  // we are Manage Order Transection data and filter 0 order id transection
  const order_payment_transactions_data =  formik.values?.order_payment_transactions?.filter((val) => val.order_id === 0) || []
  const payment_method = order_payment_transactions_data?.length > 0 && order_payment_transactions_data[0]?.payment_method
  const isDisebled = formik.values.source === ORDER_SOURCE.WALKIN.toLowerCase() && payment_method === PAYMENT_METHODS.PAYABLI ? true : false
  return (
    <div className={classes.root}>
      <div className="d-flex align-items-center order-container">
        <div className="d-flex align-items-center mr-5">
          <h3 className="bold my-0 mr-2">Recipient Information</h3>
           {/* we are manage fields using Order payment_method and order Type */}
          {isDisebled ? null : (!formik.values.is_subscribe_order || module != "new-order" ) && (formik.values.is_burq_order !== 1 &&
            <ReactLogo
              style={{ cursor: "pointer" }}
              width="30px"
              height="30px"
              onClick={() => {
                let re = [...formik.values.recipients];
                re.push({
                  recipient_id: 0,
                  sql_id: 0,
                  products: [],
                  addons: [],
                  first_name: "",
                  last_name: "",
                  video_url: "",
                  video_message: "",
                  video_message_pass_code: "",
                  video_message_qr_code: "",
                  whats_the_occasion: "",
                  whats_the_occasion_id: null,
                  greetings_on_card: "",
                  card_signature: "",
                  deleted_product_ids: [],
                });

                formik.setValues((v) => {
                  return {
                    ...v,
                    recipients: re,
                  };
                });
              }}
            />
          )}
          
        </div>
        <div style={{ flex: "1 1 0px" }}>
          <Tabs
            style={{ borderBottom: "1px solid #f2f3f5" }}
            value={active}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {formik.values.recipients.map((c, i) => (
              <Tab
                key={i}
                //  we are manage fields using Order payment_method and order Type 
                disabled={isDisebled || formik.values.is_burq_order === 1}
                label={
                  <span className="border-1 d-flex justify-content-between align-items-center w-100">
                    {c.first_name?.trim().length !== 0
                      ? c.first_name + " " + c.last_name
                      : `Recipient ${i + 1}`}

                    <Close
                      onClick={(event) => handleClose(event, i)}
                      style={{
                        width: 25,
                        height: 15,
                        marginLeft: "2rem",
                        border: "none",
                        boxShadow: "none",
                      }}
                    />
                  </span>
                }
              ></Tab>
            ))}
          </Tabs>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 my-3">
          {formik.values.recipients.map((r, i) => (
            <Recipent
              key={i}
              className={active === i ? "" : "d-none"}
              index={i}
              {...r}
            />
          ))}
        </div>
        <div className="col-md-4 my-3">
          <div className={classes.mapWrap}>
            <iframe
              width="100%"
              height="366"
              frameBorder="0"
              title="AddressMaps"
              scrolling="no"
              src={`https://maps.google.com/maps?q=Los%20Angeles+(My%20Business%20Name)&output=embed`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recipents;
