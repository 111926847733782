import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import styles from "../../Products/index.module.scss";
import AddIcon from "../../../Assets/images/add.svg";
import { useFormikContext } from "formik";
import EditIcon from "../../../Assets/images/edit.svg";

const TableMap = ({
  title,
  list,
  OnAddClick,
  onIncrease,
  onDecrease,
  onDelete,
  OnAddClickCustom,
  module,
  onEdit
}) => {
  let tr = (
    <tr>
      <td className={``}>
        <div className={`${styles.emptyImage}`}>
          <span>Image</span>
        </div>
      </td>
      <td className={``}>No {title}</td>
      <td className={` text-center`}>-</td>
      <td className={` text-center`}>-</td>
      <td className={` text-center`}>-</td>
      <td className={` text-center`}>-</td>
      <td className={` text-center `}></td>
    </tr>
  );
  const formik = useFormikContext();
  if (list.length > 0) {
    tr = list.map((d, i) => (
      <tr className={`${styles.tableStyle}`} key={i}>
        <td className={``}>
          <div className={`${styles.tableimg}`}>
            <img src={d.selectedVariant?.image} alt="" title="image" />
          </div>
        </td>
        <td className={``}>
          {d.title} <br />
          <small>{d.selectedVariant?.title}</small>
        </td>
        <td className={styles.quantityCol}>
          <div className={`d-flex align-items-center`}>
            <div className={styles.quantityLabel}>{d.qty}</div>
            <ButtonGroup className={`incressdecress ml-3`}>
              <Button disabled={d.qty <= 1 || (formik.values.is_burq_order === 1 && formik.values.is_cancel_fail_by_burq !== 1)} onClick={() => onDecrease(i)}>
                -
              </Button>
              <Button disabled={d.qty >= 1000 || (formik.values.is_burq_order === 1 && formik.values.is_cancel_fail_by_burq !== 1)} onClick={() => onIncrease(i)}>
                +
              </Button>
            </ButtonGroup>
          </div>
        </td>
        <td className={` text-center`} style={{ minWidth: "90px" }}>
          {d.selectedVariant.price.toFixed(2)}
        </td>
        <td className={` text-center`} style={{ minWidth: "90px" }}>
          {d.total_price.toFixed(2)}
        </td>
        <td className={` text-center `}>
          <div className={`d-flex`}>
            {(formik.values.is_burq_order !== 1 || (formik.values.is_burq_order === 1 && formik.values.is_cancel_fail_by_burq === 1)) &&
              <DeleteOutlineIcon
                onClick={() => {
                  if (d?.sqlid) {
                    onDelete(i, d.sqlid);
                  } else {
                    onDelete(i);
                  }
                }}
                style={{ color: "#F37C7C", cursor: "pointer" }}
              />}
            {module === 'edit-order' &&
              <img
                className={`cursor-pointer`}
                style={{width:22, height:22,marginLeft:5,marginTop:2}}
                src={EditIcon}
                alt="Edit Order"
                onClick={() => {
                  onEdit(i, d, title);
                }}
              />
            }
            
          </div>
        </td>
      </tr>
    ));
  }

  return (
    <div className={`${styles.productsWrapper}`}>
      <div className={styles.title}>{title}</div>
      {(formik.values.is_burq_order !== 1 || (formik.values.is_burq_order === 1 && formik.values.is_cancel_fail_by_burq === 1)) &&
        <span
          className={`link cursor-pointer ml-2 ${styles.searchIconWrapper}`}
          onClick={OnAddClick}>
          <img className={styles.searchIcon} src={AddIcon} alt="SearchIcon" />
        </span>
      }

      {title && title === "Products" && (
        <button type="button" disabled={formik.values.is_burq_order === 1 && formik.values.is_cancel_fail_by_burq !== 1} className={`btn pink-btn ml-2 ${styles.orderButton}`} onClick={OnAddClickCustom}>
          Add Custom Product
        </button>
      )}
      <div className="table-responsive mt-2 mb-4">
        <table className="table">
          <thead>
            <tr>
              <th className="text-left">Item</th>
              <th className="text-left">Name</th>
              <th className="text-left">Qty</th>
              <th className="text-left">Price</th>
              <th className="text-center">Total Price</th>
              <th />
            </tr>
          </thead>
          <tbody>{tr}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TableMap;
