import { DateTime } from "luxon";
import moment from "moment";
import Visa from "Assets/images/visa.svg";
import MasterCard from "Assets/images/mater-card.svg";
import AmericanExpress from "Assets/images/amex.svg";
import Discover from "Assets/images/discover.svg";
import { PAYABLI_NOT_ALLOW_STORE, PAYMENT_METHODS } from "Helpers/Constants";

export const UTC_ZONE = "UTC";
export const PST_ZONE = "America/Los_Angeles";
export const DATE_TIME_FORMAT = "yyyy-LL-dd HH:mm";
export const START_TIME_OF_DATE = "00:00 AM";
export const END_TIME_OF_DATE = "11:59 PM";
export const START_TIME_OF_DATE_TWENTY_FOUR = "00:00";
export const END_TIME_OF_DATE_TWENTY_FOUR = "23:59";
export const CUSTOM_ANYTIME = "07:00 PM";
export const CUSTOM_DEFAULT_TIME = "03:00 PM";

export function utctimeToPSTTime(utcdate,date,time) {
  if (!time) {
    return;
  }
  if(utcdate && utcdate != undefined)
  {
    let d = DateTime.fromFormat(`${utcdate}`, DATE_TIME_FORMAT, {
      zone: UTC_ZONE,
    }).setZone(PST_ZONE);
    if (d.isValid) {
      return d.toFormat("hh:mm a");
    } else {
      return "";
    }  
  }
  else if(date) {
    let d = DateTime.fromFormat(`${date} ${time}`, 'yyyy-LL-dd hh:mm a', {
      zone: UTC_ZONE,
    }).setZone(PST_ZONE);
    if (d.isValid) {
      return d.toFormat("hh:mm a");
    } else {
      return "";
    }  
  }else{
    return DateTime.fromFormat(time, "hh:mm a", { zone: UTC_ZONE })
    .setZone(PST_ZONE)
    .toFormat("hh:mm a");
  }
}
export function pstToUTCDateTime(day, timeStamp, providedTime) {
  let date = moment(day).format("YYYY-MM-DD");
  let time = "";
  if (timeStamp) {
    time = DateTime.fromSeconds(timeStamp)
      .setZone(PST_ZONE)
      .toFormat("hh:mm a");
  } else {
    time = providedTime;
  }
  return DateTime.fromFormat(`${date} ${time}`, "yyyy-LL-dd hh:mm a", {
    zone: PST_ZONE,
  })
    .setZone(UTC_ZONE)
    .toFormat(DATE_TIME_FORMAT);
}

export function timeStamptoPSTime(timeStamp) {
  if (!timeStamp) return;
  let time = DateTime.fromSeconds(timeStamp)
    .setZone(PST_ZONE)
    .toFormat("h:mm a");
  return time;
}

export function utcDateTimeToTime(date_time) {
  return DateTime.fromFormat(`${date_time}`, DATE_TIME_FORMAT).toFormat(
    "hh:mm a"
  );
}

export function utcDateTimeToPSTTime(date_time) {
  let d = DateTime.fromFormat(`${date_time}`, DATE_TIME_FORMAT, {
    zone: UTC_ZONE,
  }).setZone(PST_ZONE);
  if (d.isValid) {
    return d.toFormat("hh:mm a");
  } else {
    return "";
  }
}

export function utcTimeToDate(date_time) {
  return DateTime.fromFormat(`${date_time}`, DATE_TIME_FORMAT).toFormat(
    "yyyy-LL-dd"
  );
}

export function utcTimeToPSTTime(time) {
  if (
    DateTime.fromFormat(`${time}`, "hh:mm a", { zone: UTC_ZONE }).setLocale(
      PST_ZONE
    ).isValid
  ) {
    return DateTime.fromFormat(`${time}`, "hh:mm a", { zone: UTC_ZONE })
      .setLocale(PST_ZONE)
      .toFormat("hh:mm a");
  } else {
    return "";
  }
}

export function utcISOStringToPSTDate(date_time) {
  if (!date_time) {
    return "";
  }
  return DateTime.fromISO(date_time, { zone: UTC_ZONE })
    .setZone(PST_ZONE)
    .toFormat("yyyy-LL-dd");
}

export function ISOtoDateTime(iso) {
  if (!iso) {
    return "";
  }
  return DateTime.fromISO(iso, { zone: UTC_ZONE }).toFormat(DATE_TIME_FORMAT);
}

export function increment(product) {
  product.qty = product.qty + 1;
  product.total_price = product.qty * product.selectedVariant.price;
  return product;
}
export function decrement(product) {
  if (product.qty === 1) {
    return product;
  }
  product.qty = product.qty - 1;
  product.total_price = product.qty * product.selectedVariant.price;
  return product;
}

export function deleteProds(prods, i) {
  let ads = [...prods];
  ads.splice(i, 1);
  return ads;
}

export function getCardImage(card_type) {
  switch (card_type.toLowerCase()) {
    case "visa":
      return Visa;
    case "maestro":
      return MasterCard;
    case "master-card":
      return MasterCard;

    case "american-express":
      return AmericanExpress;
    case "mastercard":
      return MasterCard;
    case "american express":
      return AmericanExpress;
    case "discover":
      return Discover;
    case "amex":
      return AmericanExpress;
    default:
      return null;
  }
}

//Validate to not allow for specific store to place order
/**
 * @param {*} paypoint
 * @param {*} storeId
 * @returns boolean {true/false}
 */
export function validateNotAllowStoreOrder(
  payment_method,
  paypoint = null,
  storeId = 0
) {
  if (
    payment_method === PAYMENT_METHODS.PAYABLI &&
    (!paypoint || paypoint === "" || paypoint === null)
  ) {
    return true;
  } else if (
    payment_method === PAYMENT_METHODS.PAYABLI &&
    storeId &&
    +storeId > 0
  ) {
    const notAllowStores = PAYABLI_NOT_ALLOW_STORE;
    if (notAllowStores.includes(storeId)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
