import React from "react";
import ProductSection from "./SectionNewContext";

const Products = (props) => {
  return (
    <div style={{ margin: "13rem 0 0" }}>
      <ProductSection module={props.module} />
    </div>
  );
};

export default Products;
