import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import {
  getDeliveryTypes,
  getFrequnceyList,
  getOrderDetailsById,
  printGreetingMessage,
  orderMailSend,
  unsetFraudOrder,
  transferToDifferentStore,
} from "api/order";
import OrderError from "Components/OrderError/OrderError";
import { useOrder } from "Context/OrderContext/useOrder";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import defaultSignature from "../../../Assets/images/default-signature.svg";
import CardInfoSaved from "../../../Components/Card/CardInfoSaved";
import RadioBox from "../../../Components/FormControl/RadioBox/RadioBox";
import Text from "../../../Components/FormControl/Text/Text";
import TextArea from "../../../Components/FormControl/TextArea/TextArea";
import GreetingCard from "../../../Components/GreetingCard/GreetingCard";
// Components
import Header from "../../../Components/Header/Header";
import Internotes from "../../../Components/Internotes/InternalNoteView";
import styles from "../../../Components/NewOrder/index.module.scss";
import OrderSummery from "../../../Components/NewOrder/OrderSummery";
import OrderReason from "../../../Components/OrderReason/OrderReason";
import OrderProducts from "../../../Components/Products/OrderProducts";
import VideoMessage from "../../../Components/VideoMessage/VideoMessage";
import BillingForm from "../../../Components/ViewOrder/BillingForm";
import CoustumerForm from "../../../Components/ViewOrder/CoustumerForm";
import RecipentForm from "../../../Components/ViewOrder/RecipentForm";
import TotalBox from "../../../Components/ViewOrder/TotalBox";
import {
  BRAINTREE_STORE,
  ORDER_SOURCE,
  ORDER_STATUS,
  PAYMENT_METHODS,
  PAYMENT_METHOD_DETAIL,
  PAYMENT_TYPE,
} from "../../../Helpers/Constants";
import { formatUsPhone } from "../../../Hooks/commanUtility";
import TransferToDifferentStore from "Components/Modal/TransferToDifferentStore";
// Context
// Context
import { AppContext } from "./../../../Context/AppContext";
import { MODULES } from "./../../../Helpers/Constants";
import { getAccessByModuleName } from "./../../../Routes/Routes";

import EmailReSendMail from "../../../Components/Modal/EmailReSend/EmailReSend";
import { OrderConfigContext } from "Context/OrderConfigContext";
import ChangetoOnDemand from "Components/Modal/ChangetoOnDemand";

const ViewOrder = (props) => {
  const [orderDetails, setOrderDetails] = useState({});
  const [deliveryTypesList, setDeliveryTypesList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);

  const [showModal, setShowModel] = useState(false);
  const [showChantoOnDemandModel, setShowChantoOnDemandModel] = useState(false)
  const [to, setTo] = useState(1);
  const config = useContext(OrderConfigContext);
  const { showToastMessage, setIsLoading, modules, lastOrderEditPath } = useContext(AppContext);
  const [access, setAccess] = useState({});
  const { duplicateOrder, replaceOrder } = useOrder();

  // const ordersService = useMemo(() => new OrdersApi(), []);

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);


  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.VIEW_ORDER);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  useEffect(() => {
    let viewOrderAccess = getAccessByModuleName(modules, MODULES.VIEW_ORDER);
    if (viewOrderAccess && viewOrderAccess.permissions.read_access) {
      handleGetOrderDetails();
      handleGetDeliveryTypes();
      handleGetFrequency();
    }
  }, [props.match.params.id]);

  // get order details
  const handleGetOrderDetails = async () => {
    const { id } = props.match.params;
    if (!id) return;

    setIsLoading(true);
    const response = await getOrderDetailsById(id);

    if (response && response.status === 200) {
      setOrderDetails(response.data.data);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  //API call for unfraud order
  const handleUnfraudorder = async () => {
    const { id } = props.match.params;
    if (!id) return;

    setIsLoading(true);
    let postData = {
      "order_id": id
    }
    const response = await unsetFraudOrder(postData);

    if (response && response.status === 200) {
      props.history.push(lastOrderEditPath)
      showToastMessage(undefined, response.data.message, "success", false);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  // get delivery type list
  const handleGetDeliveryTypes = async () => {
    let PostData = {
      sortBy: [
        {
          id: "id",
          desc: false,
        },
      ],
    };
    const response = await getDeliveryTypes(PostData);

    if (response && response.status === 200) {
      setDeliveryTypesList(response.data.data.rows);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleGetFrequency = async () => {
    const response = await getFrequnceyList();
    if (response.status === 200) {
      let freqData = [];
      for (let k in response.data.data) {
        let freDataobj = {
          name: response.data.data[k],
          id: +k,
        };
        freqData.push(freDataobj);
      }
      setFrequencyList(freqData);
    }
  };

  // Handle Tab Button Click
  const [tabId, setTabId] = React.useState(0);
  const handleTabChange = (event, newTabId) => {
    setTabId(newTabId);
  };

  const greetingCardRePrint = async (event) => {
    event.preventDefault();
    let recipientId = orderDetails?.order_recipients[tabId]?.id;
    let orderId = orderDetails?.id;

    let PostData = {
      order_id: orderId,
      recipient_id: recipientId,
    };
    setIsLoading(true);
    try {
      const response = await printGreetingMessage(PostData);

      if (response && response.status === 200) {
        // console.log(response.data)
        window.open(response.data.data.message_url, "_blank");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
      setIsLoading(false);
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      showToastMessage(undefined, error.response?.data?.message, "error", false);
    }
  };

  const openMailSendPopup = async (event) => {
    event.preventDefault();
    setShowAddModal(true);
  };

  const orderMailReSend = async (email) => {
    let orderId = orderDetails?.id;

    const validation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let emailValidate = validation.test(String(email).toLowerCase());

    if (emailValidate) {
      setIsLoading(true);
      try {
        let postData = {
          emailId: email,
          orderId: orderId,
        };

        const response = await orderMailSend(postData);

        if (response && response.status === 200) {
          // console.log(response.data)
          showToastMessage(undefined, response.data.message, "success", false);
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToastMessage(
          undefined,
          error.response.data.message,
          "error",
          false
        );
      }
      setShowAddModal(false);
    } else {
      showToastMessage(
        undefined,
        "Please enter valid email id",
        "error",
        false
      );
    }
  };

  const getAddressLabel = (selectedText) => {
    let addressLabel = {};
    let attnLabel = "Attention";
    let addressLine2Label = "Address Line 2";

    if (selectedText) {
      if (selectedText.toLowerCase() === "house") attnLabel = "Attention";
      else if (selectedText.toLowerCase() === "apartment") {
        attnLabel = "Apartment Name";
        addressLine2Label = "Unit Number";
      } else if (selectedText.toLowerCase() === "business") {
        attnLabel = "Business Name";
        addressLine2Label = "Floor/suite Number";
      } else if (selectedText.toLowerCase() === "funeral home")
        attnLabel = "Funeralhome Name";
      else if (selectedText.toLowerCase() === "church")
        attnLabel = "Church Name";
      else if (selectedText.toLowerCase() === "hospital") {
        attnLabel = "Hospital Name";
        addressLine2Label = "Room Number";
      } else if (selectedText.toLowerCase() === "restaurant")
        attnLabel = "Restaurant Name";
      else if (selectedText.toLowerCase() === "hotel") {
        attnLabel = "Hotel Name";
        addressLine2Label = "Room Number";
      } else if (selectedText.toLowerCase() === "university") {
        attnLabel = "School Name";
        addressLine2Label = "Dorm Info";
      } else if (selectedText.toLowerCase() === "other school") {
        attnLabel = "Other School";
        addressLine2Label = "Room Number";
      }
    }

    addressLabel = {
      attnLabel: attnLabel,
      addressLine2Label: addressLine2Label,
    };
    return addressLabel;
  };

  const getKeyByValue = (objectData, value) => {
    let deliveryData = objectData.find((optionsObj) => optionsObj.id === value);
    return deliveryData ? deliveryData.name : "-";
  };

  // const formatUTCtoPST = (dt) => {
  //   let sp = dt?.trim().split(" ");
  //   let a = [...sp];
  //   let time = a[a.length - 2] + " " + a[a.length - 1];

  //   let psttime = DateTime.fromFormat(time, "hh:mm a", { zone: "UTC" })
  //     .setZone("America/Los_Angeles")
  //     .toFormat("hh:mm a");

  //   sp.pop();
  //   sp.pop();
  //   sp.push(psttime);
  //   return sp.join(" ");
  // };

  // const duplicate = () => {
  //   duplicateOrder(orderDetails);
  //   props.history.push("/admin/orders/new-order");
  // };
  // const replace = () => {
  //   replaceOrder(orderDetails);
  //   props.history.push("/admin/orders/new-order");
  // };

  // console.log('orderDetails', orderDetails);
  let orderErrors = useMemo(() => {
    let order_validation = orderDetails?.order_validations?.map((data) => {
      return {
        id: data.id ?? 0,
        order_id: data.order_id ?? 0,
        validation_type: data.validation_type ?? "",
        validation_message: data.validation_message ?? "",
        validation_status: data.validation_status ?? 0,
        updated_by: data.updated_by ?? 0,
      };
    });
    let orderErrors = order_validation?.map((data) => {
      let error = data.validation_message;
      return { error };
    });
    if (!orderDetails?.is_local_order && !orderDetails?.order_type) {
      orderErrors = orderErrors || [];
      orderErrors.push({ error: "Not a local order" });
    }
    return orderErrors;
  }, [orderDetails]);

  let delivery_time = React.useMemo(() => {
    if (orderDetails.delivery_time_type === "Anytime") {
      return orderDetails.delivery_time_type;
    } else {
      return `${orderDetails.delivery_time_type} ${DateTime.fromISO(
        orderDetails.delivery_date_time
      )
        .setZone("America/Los_Angeles")
        .toFormat("hh:mm a")}`;
    }
  }, [orderDetails]);

  let internalNotesdata = orderDetails?.order_internal_notes?.map(
    (note, index) => {
      let notesData = {
        author: note.user ? note.user.full_name : "",
        time: note.createdAt,
        ans: note.notes,
      };
      return notesData;
    }
  );

  //PAYABLI
  /**
   * @param {*} orderDetails 
   * @param {*} toStoreId 
   * @returns {boolean}
   * @description step1 : get from store and to store payment method
   * @description step2 : if from store and to store is braintree store then return false
   * @description step3 : if from store is braintree store and to store is payabli store then return true
   * @description step4 : if from store is payabli store and to store is braintree store then return true
   * @description step5 : if from store and to store is payabli store then check both store_id is same or not
   * @description step6 : if both store_id is same then return false
   * @description step7 : if both store_id is not same then return true
   */
  const validateTransferStoreOrder = (orderDetails, toStoreId = 0) => {
    const fromStoreId = orderDetails?.store_id ?? 0;
    const fromStore = PAYMENT_METHOD_DETAIL[fromStoreId];
    const toStore = PAYMENT_METHOD_DETAIL[toStoreId];

    if (fromStore === PAYMENT_METHODS.BRAINTREE &&
      toStore === PAYMENT_METHODS.BRAINTREE) {
      return false;
    } else if (fromStore === PAYMENT_METHODS.BRAINTREE &&
      toStore === PAYMENT_METHODS.PAYABLI) {
      return true;
    } else if (fromStore === PAYMENT_METHODS.PAYABLI &&
      toStore === PAYMENT_METHODS.BRAINTREE) {
      return true;
    } else {
      if (fromStore === PAYMENT_METHODS.PAYABLI &&
        toStore === PAYMENT_METHODS.PAYABLI) {
        return fromStoreId === toStoreId ? false : true;
      }
    }
    return false;
  }

  //handle order transfer to different store 
  const HandleTransferStore = async (e) => {
    e.preventDefault();
    //PAYABLI
    const isValidateTransferStoreOrder = validateTransferStoreOrder(orderDetails, to);
    if (isValidateTransferStoreOrder) {
      showToastMessage(undefined, "Sorry you can't transter this order", "error", false);
      setShowModel(false);
      return;
    }
    if (orderDetails.store.id === to) {
      showToastMessage(
        undefined,
        "You can not select same store",
        "error",
        false
      );
    } else {
      const postData = {
        order_id: orderDetails.id,
        store_id: to
      }
      const response = await transferToDifferentStore(postData)
      if (response && response.status === 200) {
        setTo(1)
        showToastMessage(undefined, response.data.message, "success", false);
        setIsLoading(false);
        setShowModel(false);
        props.history.replace("/admin/orders/list");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }

    }
  }
  let order_status = ["pending", "processing", "in_design", "design_completed", "cancel", "failed"]
  //Handle to Change Onfleet Order to OnDemand Order
  const HandleChangeOndemandOrder = async (e) => {
    e.preventDefault();
    setShowChantoOnDemandModel(false)
  }

  // we are Manage Order Transection data and filter 0 order id transection
  const order_payment_transactions = orderDetails?.order_transaction?.order_payment_transactions?.filter((val) => val.order_id === 0)


  return (
    <React.Fragment>
      <Header title="View Order" hasUnFraudOrderButton={orderDetails.is_fraud_order} handleUnFraudOrderClick={handleUnfraudorder} />
      {
        showModal ?
          <TransferToDifferentStore
            to={to}
            showModal={showModal}
            options={config.storeList}
            from={orderDetails.store.title}
            pageTitle="Order Transfer to Different Store"
            handleSubmitClick={HandleTransferStore}
            handleCancelClick={() => { setShowModel(false) }}
            onChange={(type, data) => { if (type === "store") setTo(data); }}
          />
          : ""
      }
      {
        showChantoOnDemandModel ?
          <ChangetoOnDemand
            showModal={showChantoOnDemandModel}
            pageTitle={"Change to OnDemand Order"}
            handleCancelClick={() => { setShowChantoOnDemandModel(false) }}
            handleSubmitClick={HandleChangeOndemandOrder}
            orderDetails={orderDetails}
            close={setShowChantoOnDemandModel}
          />
          : ""
      }
      <button
        onClick={(e) => {
          e.preventDefault();
          props.history.push(`/admin/orders/edit/${orderDetails?.id}`);
        }}
        className={`btn pink-btn`}
        style={{ padding: 2, "margin-top": "-35px" }}
      >
        Edit order
      </button>
      {/* we are manage condition and Transfer to different store button hide and show using transection method and Order type */}
      {orderDetails?.source === ORDER_SOURCE.WALKIN.toLowerCase() && order_payment_transactions?.length > 0 && order_payment_transactions[0]?.payment_method === PAYMENT_METHODS.PAYABLI ? null :
        BRAINTREE_STORE.includes(orderDetails?.store_id) &&
        <button
          onClick={() => {
            setShowModel(true)
          }}
          className={`btn pink-btn pl-2 pr-2 ml-2`}
          style={{ padding: 2, "margin-top": "-35px" }}
        >
          Transfer to different store
        </button>
      }
      {(orderDetails.is_burq_order !== 1 &&
        orderDetails.is_subscribe_order !== true &&
        orderDetails.order_type == 0 &&
        orderDetails.delivery_type_id == 2 &&
        orderDetails.is_local_order == 1) &&
        order_status.includes(orderDetails.order_status) ?
        <button
          onClick={() => {
            setShowChantoOnDemandModel(true)
          }}
          className={`btn pink-btn pl-2 pr-2 ml-2`}
          style={{ padding: 2, "margin-top": "-35px" }}>
          Change to OnDemand
        </button> : ""}
      {orderDetails &&
        orderDetails.order_status === "cancel" &&
        !orderDetails.is_fraud_order &&
        orderDetails.reason_for_cancellation ? (
        <OrderReason
          title={"Reason for the cancellation."}
          reason={orderDetails.reason_for_cancellation}
        />
      ) : (
        ""
      )}

      {orderDetails &&
        orderDetails.is_fraud_order &&
        orderDetails.reason_for_cancellation ? (
        <OrderReason
          title={`Reason for the ${orderDetails?.order_status === "cancel" ? "Cancellation/" : ""
            }Fraud Order`}
          reason={orderDetails.reason_for_cancellation}
        />
      ) : (
        ""
      )}

      {orderErrors && orderErrors.length !== 0 && (
        <OrderError errors={orderErrors} />
      )}

      <div id="MainContent" className="main-content">
        <div className="container-fluid order-container">
          <OrderSummery
            orderNumber={orderDetails.order_number}
            orderDate={orderDetails.createdAt}
            deliveryDate={orderDetails.delivery_date_time}
            delivery_time={delivery_time}
            deliveryStatus={
              orderDetails.order_status
                ? ORDER_STATUS[orderDetails.order_status.toUpperCase()]
                : "-"
            }
            timeDelivered={
              orderDetails && orderDetails.order_status === "delivered"
                ? orderDetails.actual_order_delivered_time
                : null
            }
            takenBy={
              orderDetails.csr_id && orderDetails.csr_detail
                ? orderDetails.csr_detail.full_name
                : orderDetails.created_by && orderDetails.created_detail
                  ? orderDetails.created_detail.full_name
                  : "-"
            }
            designedBy={
              orderDetails.designer_id && orderDetails.designer_detail
                ? orderDetails.designer_detail.full_name
                : "-"
            }
            driverName={
              orderDetails.driver_name ? orderDetails.driver_name : "-"
            }
            signature={
              orderDetails.recipient_signature_or_picture
                ? orderDetails.recipient_signature_or_picture
                : defaultSignature
            }
            source={
              orderDetails.source
                ? ORDER_SOURCE[orderDetails.source.toUpperCase()]
                : "-"
            }
            deliveringStore={orderDetails?.store}
          />

          <div className="d-md-flex align-items-md-center mt-5 mb-4">
            <h3 className="bold">
              Recipient Information
              <span className={`ml-1 mr-5`} />
            </h3>
            <Tabs
              value={tabId}
              onChange={handleTabChange}
              className="tabBorderDesign"
            >
              {orderDetails &&
                orderDetails.order_recipients &&
                orderDetails.order_recipients.map((recipient, index) => {
                  return (
                    <Tab
                      key={index}
                      id={index}
                      label={`Recipient ${index + 1}`}
                    />
                  );
                })}
            </Tabs>
          </div>

          {orderDetails &&
            orderDetails.order_recipients &&
            orderDetails.order_recipients.map((recipient, index) => {
              let addressLabel = getAddressLabel(recipient.address_type);
              recipient["attnLabel"] = addressLabel["attnLabel"];
              recipient["addressLine2Label"] =
                addressLabel["addressLine2Label"];
              return (
                <TabPanel tabId={tabId} index={index}>
                  <RecipentForm recipientData={recipient} index={index} />
                </TabPanel>
              );
            })}

          <h3 className="bold mt-5">Driver Instructions</h3>
          <div className={`row`}>
            <div className={`col-md-12 col-xs-12`}>
              <TextArea
                label="Driver Instructions"
                rows={3}
                value={
                  orderDetails.driver_instruction
                    ? orderDetails.driver_instruction
                    : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-5 col-xs-12`}>
              <RadioBox
                labelClass={styles.deliveryMatrixFormRadioLabel}
                label="Delivery Order"
                value={false}
                selectedValue={orderDetails.order_type}
                disabled={true}
              />
              <RadioBox
                label="Pickup Order"
                labelClass={styles.deliveryMatrixFormRadioLabel}
                value={true}
                selectedValue={orderDetails.order_type}
                disabled={true}
              />
            </div>
            {
              orderDetails.order_type ?
                <div className={`col-md-4 col-xs-12 store`}>
                  <Text
                    label="Select Store"
                    value={orderDetails.store.title}
                    type="text"
                    disabled={true}
                  />
                </div> : <div className="col-4"></div>
            }

            <div className={`col-md-4 col-xs-12`}>
              <Text
                type="text"
                label={
                  orderDetails.order_type === false
                    ? "Delivery Date"
                    : "Pickup Date"
                }
                value={
                  orderDetails.delivery_date_time &&
                    orderDetails.delivery_date_time !== null
                    ? DateTime.fromISO(orderDetails.delivery_date_time)
                      .setZone("America/Los_Angeles")
                      .toFormat("ccc LLL dd, yyyy")
                    : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-4 col-xs-12`}>
              <Text
                type="text"
                label="Delivery Type"
                value={
                  orderDetails.delivery_type_id &&
                    orderDetails.delivery_type_id > 0
                    ? getKeyByValue(
                      deliveryTypesList,
                      orderDetails.delivery_type_id
                    )
                    : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-4 col-xs-12`}>
              <Text
                type="text"
                label={
                  orderDetails.order_type === false
                    ? "Delivery Time"
                    : "Pickup Time"
                }
                value={
                  orderDetails.is_custom_delivery_matrix === 1
                    ? "Custom"
                    : delivery_time
                      ? delivery_time
                      : "-"
                }
                disabled={true}
              />
            </div>
            {orderDetails?.is_custom_delivery_matrix === 1 && (
              <>
                <div className={`col-md-4 col-xs-12`}>
                  <Text
                    type="text"
                    label={"Delivery Time"}
                    value={delivery_time ? delivery_time : "-"}
                    disabled={true}
                  />
                </div>
                <div className={`col-md-4 col-xs-12`}>
                  <Text
                    type="text"
                    label={"Delivery Charge"}
                    value={
                      orderDetails.delivery_charges
                        ? `$${orderDetails.delivery_charges}`
                        : "-"
                    }
                    disabled={true}
                  />
                </div>
              </>
            )}
            {orderDetails.is_subscribe_order && (
              <div className={`col-md-4 col-xs-12`}>
                <Text
                  type="text"
                  label="Subscription Type"
                  value={
                    orderDetails.subscription_order &&
                      orderDetails.subscription_order.frequency
                      ? getKeyByValue(
                        frequencyList,
                        orderDetails.subscription_order.frequency
                      )
                      : "-"
                  }
                  disabled={true}
                />
              </div>
            )}

            {orderDetails && orderDetails.shopcode ? (
              <div className={`col-md-4 col-xs-12`}>
                <Text
                  type="text"
                  label="Shop Code"
                  value={orderDetails.shopcode ? orderDetails.shopcode : "-"}
                  disabled={true}
                />
              </div>
            ) : (
              ""
            )}
            {orderDetails && orderDetails.external_shop_name ? (
              <div className={`col-md-4 col-xs-12`}>
                <Text
                  type="text"
                  label="Shop Name"
                  value={
                    orderDetails.external_shop_name
                      ? orderDetails.external_shop_name
                      : "-"
                  }
                  disabled={true}
                />
              </div>
            ) : (
              ""
            )}
            {orderDetails && orderDetails.external_person_name ? (
              <div className={`col-md-4 col-xs-12`}>
                <Text
                  type="text"
                  label="Person Name"
                  value={
                    orderDetails.external_person_name
                      ? orderDetails.external_person_name
                      : "-"
                  }
                  disabled={true}
                />
              </div>
            ) : (
              ""
            )}
            {orderDetails && orderDetails.external_order_number ? (
              <div className={`col-md-4 col-xs-12`}>
                <Text
                  type="text"
                  label="External Order Number"
                  value={
                    orderDetails.external_order_number
                      ? orderDetails.external_order_number
                      : "-"
                  }
                  disabled={true}
                />
              </div>
            ) : (
              ""
            )}
            {orderDetails && orderDetails.external_phone_number ? (
              <div className={`col-md-4 col-xs-12`}>
                <Text
                  type="text"
                  label="Phone Number"
                  value={
                    orderDetails.external_phone_number
                      ? formatUsPhone(orderDetails.external_phone_number)
                      : "-"
                  }
                  disabled={true}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          {orderDetails &&
            orderDetails.order_recipients &&
            orderDetails.order_recipients.map((recipient, index) => {
              return (
                <TabPanel tabId={tabId} index={index}>
                  <div className={`row`}>
                    <div className={`col-md-6 col-xs-12`}>
                      <h3 className="bold mt-5 mb-4">Greetings On The Card</h3>
                      {recipient.greetings_on_card ? (
                        <GreetingCard
                          rows={5}
                          value={recipient.greetings_on_card}
                          disabled={true}
                          signature={
                            recipient.card_signature
                              ? recipient.card_signature
                              : ""
                          }
                          video_url={recipient.video_url}
                        />
                      ) : (
                        "-"
                      )}
                      <div className="mt-3">
                        <Text
                          type="text"
                          label="Card Signature"
                          value={
                            recipient.card_signature
                              ? recipient.card_signature
                              : "-"
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    {recipient.video_message || recipient.video_url ? (
                      <div className={`col-md-6 col-xs-12`}>
                        <h3 className="bold mt-5 mb-4">Video Message</h3>
                        <VideoMessage
                          URL={recipient.video_url}
                          message={recipient.video_message}
                        />
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </TabPanel>
              );
            })}

          {orderDetails &&
            orderDetails.order_recipients &&
            orderDetails.order_recipients.map((recipient, index) => {
              let productsData = recipient.order_products.filter(
                (product) => product.product_type === false
              );
              let addonsData = recipient.order_products.filter(
                (product) => product.product_type === true
              );
              return (
                <TabPanel tabId={tabId} index={index}>
                  <div className={`row`}>
                    <div className={`col-md-6 col-xs-12 mt-5 mb-4`}>
                      <OrderProducts
                        title={"Products"}
                        itemData={productsData}
                      />
                    </div>

                    <div className={`col-md-6 col-xs-12 mt-5 mb-4`}>
                      <OrderProducts title={"Add Ons"} itemData={addonsData} />
                    </div>
                  </div>
                </TabPanel>
              );
            })}

          <h3 className="bold mt-5">Designer Instructions</h3>
          <div className={`row`}>
            <div className={`col-md-12 col-xs-12`}>
              <TextArea
                label="Designer Instructions"
                rows={3}
                value={
                  orderDetails.designer_instruction
                    ? orderDetails.designer_instruction
                    : "-"
                }
                disabled={true}
              />
            </div>
          </div>

          <div className={`row`}>
            <div className={`col-md-8 col-xs-12`}>
              <h3 className="bold mt-5 mb-4">Payment Types</h3>
              <div className={`row`}>
                <div className={`col-md-6 col-xs-12`}>
                  <div className={`row`}>
                    <div className={`col-md-8`}>
                      {orderDetails && orderDetails.order_transaction ? (
                        <Text
                          type="text"
                          label="Payment Type"
                          value={
                            orderDetails.order_transaction
                              ?.order_payment_transactions[0]?.transaction_type
                              ? PAYMENT_TYPE[
                              orderDetails.order_transaction?.order_payment_transactions[0]?.transaction_type?.toUpperCase()
                              ]
                              : "-"
                          }
                          disabled={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={`col-md-4`}></div>
                  </div>
                  {orderDetails && orderDetails.order_transaction ? (
                    <CardInfoSaved
                      tr_type={
                        orderDetails.order_transaction
                          ?.order_payment_transactions[0]?.transaction_type
                      }
                      card_last_4_digits={
                        orderDetails.order_transaction
                          .order_payment_transactions[0]?.card_last_4_digits
                      }
                      isViewOrder={true}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className={`col-md-6 col-xs-12`}>
                  <TotalBox orderData={orderDetails} />
                </div>
              </div>
            </div>

            <div className={`col-md-4 col-xs-12`}>
              <h3 className="bold mt-5 mb-4">Internal Notes</h3>
              <Internotes
                data={internalNotesdata}
                isViewOrder={true}
                isEdit={false}
              />
            </div>
          </div>

          <h3 className="bold mt-5 mb-4">Billing Information</h3>
          {orderDetails && orderDetails.order_transaction ? (
            <BillingForm billingData={orderDetails.order_transaction} />
          ) : (
            ""
          )}
          <h3 className="bold mt-5 mb-4">Customer Information</h3>
          {orderDetails && orderDetails.order_transaction ? (
            <CoustumerForm coustumerData={orderDetails.order_transaction} />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="col-md-12" style={{ "margin-bottom": "100px" }}>
        <button
          onClick={greetingCardRePrint}
          className={`btn pink-btn`}
          style={{ float: "right" }}
        >
          Re Print Greeting Card
        </button>

        <button
          onClick={openMailSendPopup}
          className={`btn pink-btn mr-2`}
          style={{ float: "right" }}
        >
          Re Send EMail
        </button>
      </div>

      <EmailReSendMail
        showModal={showAddModal}
        pageTitle="Add Email"
        email={orderDetails?.order_transaction?.customer_email}
        handleCancelClick={(e) => {
          setShowAddModal(false);
        }}
        submitStatus={submitStatus}
        handleSubmitClick={orderMailReSend}
      />
    </React.Fragment>
  );
};

export default withRouter(ViewOrder);

function TabPanel(props) {
  const { children, tabId, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabId !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabId === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
